import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useUpdateAdvanceKycMutation } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export const RejectKycFormSchema = yup.object({
	reason: yup.string().required('Please provide a reason'),
});

export type RejectKycFormSchemaType = yup.InferType<typeof RejectKycFormSchema>;

const RejectKycModal = () => {
	const modals = useAppSelector(selectModals);

	const currentModal = modals.find((modal) => modal.id === 'rejectKyc');
	const kycId = currentModal?.metadata as string;

	const [updateAdvanceKyc, { isLoading }] = useUpdateAdvanceKycMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(RejectKycFormSchema),
	});

	const onSubmit = useCallback(
		(payload: RejectKycFormSchemaType) => {
			updateAdvanceKyc({ kycId, verify: false, reason: payload.reason })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ message: resp.message, type: 'success' });
					closeModalById('rejectKyc');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[kycId, updateAdvanceKyc]
	);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<TextareaInput
				label='Rejection Reason'
				placeholder='Rejection Reason'
				rows={5}
				register={register('reason')}
				errorMsg={errors.reason?.message}
			/>
			<Button
				size='large'
				variant='red'
				loading={isLoading}
			>
				Reject
			</Button>
		</form>
	);
};

export default RejectKycModal;
