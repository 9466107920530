import { Button, Text, TextareaInput } from 'BreetComponents';
import { type RouteParamsType } from 'BreetConfig';
import { dateConst, spawnAppToast } from 'BreetHelpers';
import { useGetUserNoteHistoryQuery, useUpdateTaggedUserNoteMutation } from 'BreetRedux';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ArrowIcon, EditIcon, MarkCorrectIcon } from '@/assets/icons';
import { withSlidePagination, type WithSlidePaginationProps } from '@/HOC';

const TaggedUserNotes = ({
	isEditUser,
	extUserId,
	currentStepIndex,
	translateX,
	slideWidth,
	totalSlideCount,
	onNextStepClick,
	onPrevStepClick,
	updateTotalSlideCount,
}: Partial<WithSlidePaginationProps> & { isEditUser?: boolean; extUserId?: string }) => {
	const [editMode, setEditMode] = useState(false);
	const [edittedNote, setEdittedNote] = useState('');

	const { userId } = useParams<RouteParamsType>();
	const location = useLocation();
	const navigate = useNavigate();

	const { data } = useGetUserNoteHistoryQuery(
		{ userId: extUserId ?? userId, filter: JSON.stringify({ resolved: false }) },
		{ skip: !(extUserId ?? userId) }
	);
	const [updateTaggedUserNote, { isLoading }] = useUpdateTaggedUserNoteMutation();

	useEffect(() => {
		if (data?.data) {
			updateTotalSlideCount?.(data.data.length ? data.data.length : 1);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.data]);

	const handleNextStep = useCallback(() => {
		if (editMode) setEditMode(false);
		onNextStepClick?.();
	}, [editMode, onNextStepClick]);

	const handlePrevStep = useCallback(() => {
		if (editMode) setEditMode(false);
		onPrevStepClick?.();
	}, [editMode, onPrevStepClick]);

	const handleTaggedNoteChange = useCallback((value: string) => {
		setEdittedNote(value);
	}, []);

	const handleUpdateTaggedUserNote = useCallback(
		(removeNote?: boolean) => () => {
			const currentNote = data?.data?.[currentStepIndex ?? 0];

			if (!currentNote) {
				setEditMode(false);
				return;
			}

			updateTaggedUserNote({ noteId: currentNote._id, note: edittedNote, removeNote })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					setEditMode(false);

					const prevLocation = (location.state as Record<string, string> | undefined)?.pathname;
					if (prevLocation) navigate(prevLocation);
					else window.history.back();
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[currentStepIndex, data?.data, edittedNote, location.state, navigate, updateTaggedUserNote]
	);

	const handleToggleEditNote = useCallback(() => {
		if (!editMode) {
			setEditMode(true);
			return;
		}

		handleUpdateTaggedUserNote()();
	}, [editMode, handleUpdateTaggedUserNote]);

	return (
		<div className='taggedUserNotes_wrapper'>
			<div className='taggedUserNotes'>
				{!!data?.data?.length && (
					<div className='taggedUserNotes_nav align-flex-center'>
						<div className='taggedUserNotes_nav_cta align-flex-center'>
							<Button
								type='button'
								btnType='icon'
								size='small'
								variant='white'
								iconEl={<ArrowIcon type='leftCaret' />}
								onClick={handlePrevStep}
								disabled={isLoading || currentStepIndex === 0}
							/>

							<Button
								type='button'
								btnType='icon'
								size='small'
								variant='white'
								iconEl={<ArrowIcon type='rightCaret' />}
								onClick={handleNextStep}
								disabled={isLoading || currentStepIndex === (totalSlideCount ?? 1) - 1}
							/>
						</div>
						<div className='taggedUserNotes_nav_value'>
							<Text variant='body_default_regular'>
								{(currentStepIndex ?? 0) + 1}&nbsp;/&nbsp;{totalSlideCount ?? 1}
							</Text>
						</div>
					</div>
				)}

				<div className='taggedNote_slide'>
					<div
						className='taggedNote_slide_wrapper'
						style={{ transform: `translateX(${translateX}px)` }}
					>
						<div style={{ width: `calc(${(slideWidth ?? 0) * (totalSlideCount ?? 1)}0px - 20px)` }}>
							{data?.data?.length ? (
								data.data.map((userNote) => (
									<div key={userNote._id}>
										<TextareaInput
											wrapperClassName='taggedNote'
											rows={8}
											extValue={userNote.note}
											onChange={handleTaggedNoteChange}
											readOnly={!editMode}
										/>
										<Text
											variant='body_small_regular'
											className='taggedNote_date'
										>
											{format(parseISO(userNote.createdAt), dateConst.FULL_DATE_TIME_FORMAT2)}
										</Text>
									</div>
								))
							) : (
								<TextareaInput
									wrapperClassName='taggedNote'
									rows={8}
									disabled
									readOnly
								/>
							)}
						</div>
					</div>
				</div>
			</div>

			{isEditUser && !!data?.data?.length && (
				<div className='taggedUserNotes_mainCta'>
					<Button
						size='small'
						onClick={handleToggleEditNote}
						type='button'
						variant={editMode ? 'alternate' : 'blue'}
						loading={isLoading}
					>
						{editMode ? <MarkCorrectIcon type='double-check' /> : <EditIcon />}
						&nbsp;&nbsp;{editMode ? 'Update' : 'Edit'}
					</Button>

					<Button
						size='small'
						type='button'
						disabled={isLoading}
						onClick={handleUpdateTaggedUserNote(true)}
					>
						<MarkCorrectIcon type='double-check' />
						&nbsp;&nbsp;Resolve
					</Button>
				</div>
			)}
		</div>
	);
};

const TaggedUserNotesWithHelper = withSlidePagination(TaggedUserNotes);
export default TaggedUserNotesWithHelper;
