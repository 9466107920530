import { dateConst } from 'BreetHelpers';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';

import { CloseIcon, MarkCorrectIcon } from '@/assets/icons';
import TaggedUserNotesWithHelper from '@/features/user-management/users/[userId]/userActions/TaggedUserNotes';

import { Button } from '../Button';
import { SkeletonLoader } from '../Loader';
import { UserTableColumn } from '../Table';
import { StatusText, Text } from '../Text';
import { Toggle } from '../Toggle';
import type { IDataInsight } from './DataInsight.helpers';

const DataInsight = ({
	title,
	className,
	txtClassName,
	value,
	isLoading,
	user,
	note,
	status,
	statusText,
	dateValue,
	auto,
	customValue,
	imgProps,
	toggleProps,
	gridAlign = 'row',
}: IDataInsight) => {
	const wrapperClassName = className ? ` ${className}` : '';
	const txtValueClassName = txtClassName ? ` ${txtClassName}` : '';

	const renderUserInsight = useCallback(() => {
		if (user)
			return isLoading ? (
				<SkeletonLoader
					type='square'
					width='8.5rem'
					height='1.7rem'
				/>
			) : (
				<UserTableColumn {...user} />
			);
	}, [isLoading, user]);

	const renderNoteInsight = useCallback(() => {
		if (note?.userId)
			return (
				<span className='bt_dataInsight_value_note'>
					{isLoading ? (
						<SkeletonLoader
							type='square'
							width='14rem'
							height='1.9rem'
						/>
					) : (
						<TaggedUserNotesWithHelper extUserId={note.userId} />
					)}
				</span>
			);

		return (
			<Text
				className={`bt_dataInsight_value_txt${txtValueClassName}`}
				variant='body_default_regular'
			>
				{value ?? '----'}
			</Text>
		);
	}, [isLoading, note, txtValueClassName, value]);

	const renderAutoInsight = useCallback(() => {
		if (auto !== undefined)
			return isLoading ? (
				<SkeletonLoader
					type='circle'
					width='4rem'
					height='4rem'
				/>
			) : (
				<Button
					size='small'
					variant={auto ? 'main' : 'red'}
					btnType='icon'
					iconEl={auto ? <MarkCorrectIcon /> : <CloseIcon />}
				/>
			);
	}, [isLoading, auto]);

	const renderStatusInsight = useCallback(() => {
		if (status)
			return isLoading ? (
				<SkeletonLoader
					type='square'
					width='8.5rem'
					height='3.5rem'
				/>
			) : (
				<StatusText
					status={status}
					text={statusText ?? status}
				/>
			);
	}, [isLoading, status, statusText]);

	const renderDateValueInsight = useCallback(() => {
		if (dateValue && typeof value === 'string')
			return isLoading ? (
				<SkeletonLoader
					type='square'
					width='16rem'
					height='1.9rem'
				/>
			) : (
				<Text
					className={`bt_dataInsight_value_txt${txtValueClassName}`}
					variant='body_default_regular'
				>
					{format(parseISO(value), dateConst.FULL_DATE_TIME_FORMAT2)}
				</Text>
			);
	}, [dateValue, value, isLoading, txtValueClassName]);

	const renderImageValueInsight = useCallback(() => {
		if (imgProps)
			return isLoading ? (
				<SkeletonLoader
					type='square'
					width={`${imgProps.width}px`}
					height={`${imgProps.height}px`}
				/>
			) : (
				<span className='bt_dataInsight_value_img'>
					<img
						src={imgProps.src}
						width={imgProps.width}
						height={imgProps.height}
						alt={title}
					/>
				</span>
			);
	}, [imgProps, isLoading, title]);

	const renderToggleInsight = useCallback(() => {
		if (toggleProps)
			return (
				<Toggle
					id={title}
					{...toggleProps}
				/>
			);
	}, [title, toggleProps]);

	const renderInsightValue = useCallback(() => {
		if (isLoading)
			return (
				<SkeletonLoader
					type='square'
					width='20rem'
					height='1.9rem'
				/>
			);

		if (customValue) return customValue;

		if (user) return renderUserInsight();

		if (note) return renderNoteInsight();

		if (auto !== undefined) return renderAutoInsight();

		if (dateValue && typeof value === 'string') return renderDateValueInsight();

		if (status) return renderStatusInsight();

		if (imgProps) return renderImageValueInsight();

		if (toggleProps) return renderToggleInsight();

		return (
			<Text
				className={`bt_dataInsight_value_txt${txtValueClassName}`}
				variant='body_default_regular'
			>
				{value ?? '----'}
			</Text>
		);
	}, [
		auto,
		customValue,
		dateValue,
		imgProps,
		isLoading,
		note,
		renderAutoInsight,
		renderDateValueInsight,
		renderImageValueInsight,
		renderNoteInsight,
		renderStatusInsight,
		renderToggleInsight,
		renderUserInsight,
		status,
		toggleProps,
		txtValueClassName,
		user,
		value,
	]);

	return (
		<div className={`bt_dataInsight ${gridAlign}${wrapperClassName}`}>
			<Text
				className='bt_dataInsight_title'
				variant='body_default_bold'
			>
				{title}
			</Text>
			<div className='bt_dataInsight_value'>{renderInsightValue()}</div>
		</div>
	);
};

export default DataInsight;
