/* eslint-disable react/destructuring-assignment */

import React, { useCallback, useState } from 'react';

export interface WithSlidePaginationProps {
	slideWidth?: number;
	translateX?: number; // transition width size
	totalSlideCount?: number;
	currentStepIndex?: number;
	onPrevStepClick?: () => void;
	onNextStepClick?: () => void;
	updateTotalSlideCount?: (count: number) => void;
}

export const withSlidePagination = <P extends WithSlidePaginationProps>(Component: React.ComponentType<P>) => {
	const EnhacedComponent = (props: P) => {
		const [currentStepIndex, setCurrentStepIndex] = useState(0);
		const [totalSlideCount, setTotalSlideCount] = useState(1);

		const slideWidth = props.slideWidth ?? 500;
		const translateX = -currentStepIndex * slideWidth;

		const handleTotalSlideCountUpdate = useCallback((count: number) => {
			setTotalSlideCount(count);
		}, []);

		const handleNextStep = useCallback(() => {
			setCurrentStepIndex((prevIndex) => (prevIndex + 1) % totalSlideCount);
		}, [totalSlideCount]);

		const handlePrevStep = useCallback(() => {
			if (currentStepIndex) setCurrentStepIndex((prevIndex) => (prevIndex - 1) % totalSlideCount);
		}, [currentStepIndex, totalSlideCount]);

		return (
			<Component
				{...props}
				slideWidth={slideWidth}
				translateX={translateX}
				totalSlideCount={totalSlideCount}
				currentStepIndex={currentStepIndex}
				onNextStepClick={handleNextStep}
				onPrevStepClick={handlePrevStep}
				updateTotalSlideCount={handleTotalSlideCountUpdate}
			/>
		);
	};

	return EnhacedComponent;
};
