import type {
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	IUpdateKycPayload,
	IUserAml,
	IUserKyc,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const kycsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getKycs: builder.query<IApiResponsePaginated<IUserKyc[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/users/kycs', params }),
			providesTags: ['GetKycs'],
		}),
		getKycDetails: builder.query<IApiResponse<IUserKyc>, string | undefined>({
			query: (kycId) => ({ url: `/admin/users/kyc/${kycId}` }),
			providesTags: ['GetKycDetails'],
		}),
		getUserKycDetails: builder.query<IApiResponse<IUserKyc[]>, string | undefined>({
			query: (userId) => ({ url: `/admin/users/user/${userId}/kycs` }),
			providesTags: ['GetUserKycDetails'],
		}),
		getRecentAmlScreening: builder.query<IApiResponsePaginated<IUserAml[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/users/aml', params }),
		}),
		updateAdvanceKyc: builder.mutation<IApiResponse, IUpdateKycPayload>({
			query: ({ kycId, verify, reason }) => ({
				method: 'PUT',
				url: `/admin/users/kyc/${kycId}/advance`,
				body: { status: verify ? 'completed' : 'failed', reason },
			}),
			invalidatesTags: ['GetKycs', 'GetKycDetails', 'GetUserKycDetails'],
		}),
	}),
});

export const {
	useGetKycsQuery,
	useGetKycDetailsQuery,
	useGetUserKycDetailsQuery,
	useGetRecentAmlScreeningQuery,
	useUpdateAdvanceKycMutation,
} = kycsApiSlice;
