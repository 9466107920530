import { Toggle } from 'BreetComponents';
import { selectThemeMode, setThemeMode, useAppDispatch, useAppSelector } from 'BreetRedux';
import { useCallback } from 'react';

import { StarMoonIcon } from '@/assets/icons';

const ThemeToggle = () => {
	const themeMode = useAppSelector(selectThemeMode);
	const dispatch = useAppDispatch();

	const themeToggleHandler = useCallback(
		(val: boolean) => {
			if (!val) dispatch(setThemeMode('light'));
			if (val) dispatch(setThemeMode('dark'));
		},
		[dispatch]
	);

	return (
		<Toggle
			id='themeModeToggle'
			checked={themeMode === 'dark'}
			className='themeModeToggle'
			onToggleChange={themeToggleHandler}
			leftIcon={<StarMoonIcon type='star' />}
			rightIcon={<StarMoonIcon type='moon' />}
		/>
	);
};

export default ThemeToggle;

ThemeToggle.displayName = 'BreetThemeToggle';
