import type { UserCountryType, UserDeviceType } from '../types';

const TIME_FORMAT = 'hh:m a';
const DATE_FORMAT = 'MMM, dd yyyy';
const FULL_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss zzz';
const FULL_DATE_TIME_FORMAT2 = 'MMMM d yyyy, h:mm aa';
const CALENDAR_DATE_TIME_FORMAT = 'MMMM d, yyyy h:mm aa';
export const MS_IN_SECOND = 1000;
export const MS_IN_TEN_SECONDS = MS_IN_SECOND * 10;
export const MS_IN_MINUTE = MS_IN_SECOND * 60;
export const MS_IN_HOUR = MS_IN_MINUTE * 60;
export const MS_IN_DAY = MS_IN_HOUR * 24;

export const dateConst = { TIME_FORMAT, DATE_FORMAT, FULL_DATE_TIME_FORMAT, FULL_DATE_TIME_FORMAT2, CALENDAR_DATE_TIME_FORMAT };

export const SORT_LATEST_TO_OLDEST = -1;
export const SORT_OLDEST_TO_LATEST = 1;

export const sortingReqOptions = [
	{
		id: 'latestToOldest',
		label: 'Latest to Oldest',
		value: SORT_LATEST_TO_OLDEST,
	},
	{
		id: 'oldestToLatest',
		label: 'Oldest to Latest',
		value: SORT_OLDEST_TO_LATEST,
	},
];

export const sortingHighLowReqOptions = [
	{
		id: 'highestToLowest',
		label: 'Highest to Lowest',
		value: SORT_LATEST_TO_OLDEST,
	},
	{
		id: 'lowestToHighest',
		label: 'Lowest to Highest',
		value: SORT_OLDEST_TO_LATEST,
	},
];

export const clientChannelOptions: {
	id: UserDeviceType;
	label: string;
	value: UserDeviceType;
}[] = [
	{
		id: 'web',
		label: 'Website',
		value: 'web',
	},
	{
		id: 'ios',
		label: 'Mobile App (iOS)',
		value: 'ios',
	},
	{
		id: 'android',
		label: 'Mobile App (Android)',
		value: 'android',
	},
];

export const clientCountryObj: Record<
	UserCountryType,
	{ country: string; currencyValue: string; currencyName: string; currencySign: string }
> = {
	nigeria: {
		country: 'Nigeria',
		currencyValue: 'NGN',
		currencyName: 'Naira',
		currencySign: '₦',
	},
	ghana: {
		country: 'Ghana',
		currencyValue: 'GHS',
		currencyName: 'Cedis',
		currencySign: '₵',
	},
};

export const usersCountryOptions = [
	{
		id: 'ngn',
		label: 'Nigeria',
		value: 'nigeria',
	},
	{
		id: 'ghs',
		label: 'Ghana',
		value: 'ghana',
	},
];
