import { matchStyle } from 'BreetHelpers';
import { forwardRef } from 'react';

import { ButtonProps } from './Button.helper';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			children,
			as,
			variant = 'main',
			size = 'regular',
			btnType = 'default',
			prefixIcon,
			suffixIcon,
			iconEl,
			loading,
			disabled,
			...rest
		},
		ref
	) => {
		const wrapClassName = matchStyle(className, {
			[`${className}`]: ` ${className}`,
			default: '',
		});

		const Component = as ?? 'button';

		if (btnType === 'icon' && !iconEl) throw Error('`iconEl` needs to be specified when btnType is icon');
		if (btnType !== 'icon' && iconEl) throw Error('`btnType` needs to be icon to use iconEl');

		return (
			<Component
				ref={ref}
				{...rest}
				disabled={disabled ?? loading}
				className={`bt_button ${variant} ${size} ${btnType}${wrapClassName}`}
			>
				{(loading ?? !!prefixIcon) && (
					<span className='bt_button_prefixIcon'>{loading ? <span className='bt_button_isLoading' /> : prefixIcon}</span>
				)}
				{iconEl ? <span className='bt_button_iconEl'>{iconEl}</span> : children}
				{!!suffixIcon && (
					<span className='bt_button_suffixIcon'>{loading ? <span className='bt_button_isLoading' /> : suffixIcon}</span>
				)}
			</Component>
		);
	}
);

export default Button;

Button.displayName = 'BreetButton';
